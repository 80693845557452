import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  List,
  ListItem,
  Hidden
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from '../../context/LayoutContext'
import { UserContext } from '../../context/User'
import Logo from '../Logo'
import { useWeb3React } from '@web3-react/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import classNames from 'classnames'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Scroll from 'react-scroll'
const ScrollLink = Scroll.Link

const headersData = [
  // {
  //   label: "Home",
  //   href: "/app/landingpage",
  // },
  {
    label: 'FEATURES',
    href: '/app/features'
  },
  {
    label: 'CONTACT',
    href: '/app/contact'
  },
  {
    label: 'MEMBERSHIP',
    href: '/app/membership'
  }
]

const useStyles = makeStyles(theme => ({
  menuButton: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    borderRadius: 0,
    minWidth: 'auto',
    color: '#f6f3f7',
    height: '31px',
    padding: '0px 7px',
    letterSpacing: '1px',
    '@media (max-width: 900px)': {
      fontSize: '14px',
      letterSpacing: '1px',
      fontWeight: '500',
      lineHeight: '36px',
      color: '#FFF',
      padding: '4px 9px',
      height: '51px',
      width: '100%',
      display: 'flex',
      fontFamily: '"Roboto", sans-serif !important',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    '&:active': {
      color: '#1f5fad'
    },
    '&:hover': {
      color: '#1f5fad'
    }
  },
  toolbar: {
    display: 'flex',
    padding: '10px 0',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingRight: '20px',
      height: '100%'
    }
  },
  maindrawer: {
    height: '100%',
    background: '#0c0731',
    width: '260px'
  },
  logoDrawer: {
    paddingLeft: '10px',
    width: '210px',
    marginBottom: '10px'
  },
  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#000',
    width: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '7px',
    right: '-10px',
    fontSize: '25px'
  },
  logoImg: {
    width: '75px',
    // height: '44.5px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 0px',
      width: '52px'
    }
  },
  logoImg1: {
    width: '200px',
    // height: '44.5px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 12px',
      width: '100%'
    }
  },
  logoImg12: {
    width: '75px',
    // height: '44.5px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 12px',
      width: '100%'
    }
  },
  menuMobile: {
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#fff',
    // borderBottom: "1px solid #3e3e3e",
    padding: '16px',
    '@media (max-width: 500px)': {
      padding: '7px 0',
      width: '100%'
    }
  },
  paper1: {
    background: 'black',
    color: 'white'
  },
  containerHeight: {
    height: '100%'
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px'
  },
  search: {
    height: '31px',
    position: 'relative',
    color: '#ABABAB',
    borderRadius: '100px',
    backgroundColor: '#E6E6E6',
    border: '1px solid #fff',
    '&:hover': {
      backgroundColor: '#ececec',
      border: '1px solid #300760'
    },
    marginLeft: 20,
    width: '140px',
    maxWidth: '257px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '180px'
    }
  },
  searchIcon: {
    fontSize: '16px',
    padding: theme.spacing(0, 2),
    color: '#000000',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    fontSize: '16px'
  },
  wallet: {
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '21px',
    color: '#fff',
    border: '1px solid #ec0066',
    padding: '0 15px',
    background: '#ec0066',
    borderRadius: '50px',
    height: '31px',
    '&:hover': {
      background: '#fff',
      color: '#ec0066'
    },
    '@media (max-width: 900px)': {
      marginLeft: '12px',
      marginTop: '12px'
    }
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: '13px',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%'
      }
    }
  },
  menuButtonBtn: {
    backgroundColor: '#1f5fad',
    borderRadius: '50px',
    color: '#fff',
    height: '40px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '10px'
  },
  submenu: {
    borderTop: '3px solid #300760',
    top: '25px !important'
  }
}))

export default function Header () {
  const [layoutCheck, setLayoutCheck] = useState(false)
  const [layoutCheck1, setLayoutCheck1] = useState(false)
  const location = useLocation()
  const user = useContext(UserContext)
  const { account } = useWeb3React()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    const urlCheck = window.location.href.split('#')
    // console.log("urlCheck----", urlCheck);
    if (urlCheck[1] === '/app/landingpage') {
      setLayoutCheck(true)
    } else {
      setLayoutCheck(false)
    }
    if (urlCheck[1] === '/app/dashboard') {
      setLayoutCheck1(true)
    } else {
      setLayoutCheck1(false)
    }
  }, [window.location.href])

  var layoutState = useLayoutState()

  var classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  var layoutState = useLayoutState()
  var layoutDispatch = useLayoutDispatch()
  const handleClose1 = () => {
    setAnchorEl(null)
  }
  const {
    menuMobile,
    menuButton,
    menuButton1,
    menuButtonBtn,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu
  } = useStyles()
  const history = useHistory()
  // console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const [open1, setOpen1] = useState({ community: false, user: false })
  const anchorRef = { community: useRef(null), user: useRef(null) }

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return
    }

    setOpen1({ ...open1, [name]: false })
  }

  function handleListKeyDown (event, name) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen1({ ...open1, [name]: false })
    }
  }
  // console.log("layoutCheck---------------", layoutCheck);
  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Hidden mdDown>{femmecubatorLogo}</Hidden>
        <Grid
          container
          item
          direction='row'
          justify='flex-end'
          alignItems='center'
          style={{ paddingLeft: '0px' }}
        >
          {history.location.pathname !== '/' ? (
            <List
              style={{
                listStyle: 'none',
                cursor: 'pointer',
                paddingLeft: '0px',
                paddingTop: '8px'
              }}
            >
              <ListItem>
                <Link
                  className={menuButton}
                  to='/'
                  style={{ textDecoration: 'none ' }}
                >
                  HOME{' '}
                </Link>
              </ListItem>
            </List>
          ) : (
            <List
              style={{
                listStyle: 'none',
                cursor: 'pointer',
                paddingLeft: '0px',
                paddingTop: '8px'
              }}
            >
              {/* {console.log(history.location)} */}
              <ListItem>
                <ScrollLink
                  className={menuButton}
                  smooth={true}
                  duration={0}
                  style={{ textDecoration: 'none ', cursor: 'pointer' }}
                  to='section3'
                >
                  HOME{' '}
                </ScrollLink>
              </ListItem>
            </List>
          )}
          {getMenuButtons()}
          {/* {account && user.isLogin ? ( */}
          <>
            {layoutCheck && (
              <Button
                ref={anchorRef.community}
                aria-controls={open1.community ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                component={Link}
                to='/app/dashboard'
                // onClick={() => handleToggle("community")}
                // endIcon={<ExpandMoreIcon />}
                className={menuButtonBtn}
              >
                ENTER APP
              </Button>
            )}
            {!layoutCheck && (
              <Button
                ref={anchorRef.community}
                aria-controls={open1.community ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                component={Link}
                to='/app/dashboard'
                // onClick={() => handleToggle("community")}
                // endIcon={<ExpandMoreIcon />}
                className={menuButtonBtn}
              >
                Dashboard
              </Button>
            )}
          </>
          {/* ) : (
            <Button
              ref={anchorRef.community}
              aria-controls={open1.community ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={user.connectWallet}
              className={menuButtonBtn}
            >
              CONNECT
            </Button>
          )} */}
        </Grid>
      </Toolbar>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src='/images/logo1.svg' alt='' />
            {history.location.pathname !== '/' ? (
              <List
                style={{
                  listStyle: 'none',
                  cursor: 'pointer',
                  paddingLeft: '0px',
                  paddingTop: '8px'
                }}
              >
                <ListItem>
                  <Link
                    className={menuButton}
                    to='/'
                    style={{ textDecoration: 'none' }}
                  >
                    HOME{' '}
                  </Link>
                </ListItem>
              </List>
            ) : (
              <List
                style={{
                  listStyle: 'none',
                  cursor: 'pointer',
                  paddingLeft: '0px',
                  textDecoration: 'none '
                }}
              >
                {/* {console.log(history.location)} */}
                <ListItem>
                  <ScrollLink
                    className={menuButton}
                    smooth={true}
                    duration={0}
                    scrollTo='0'
                    style={{ textDecoration: 'none ', cursor: 'pointer' }}
                    to='section3'
                  >
                    HOME{' '}
                  </ScrollLink>
                </ListItem>
              </List>
            )}
            {getDrawerChoices()}
            {/* {account ? ( */}
            <>
              {layoutCheck && (
                <Button
                  ref={anchorRef.community}
                  aria-controls={open1.community ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  component={Link}
                  to='/app/dashboard'
                  // onClick={() => handleToggle("community")}
                  // endIcon={<ExpandMoreIcon />}
                  className={menuButtonBtn}
                >
                  ENTER APP
                </Button>
              )}
              {!layoutCheck && (
                <Button
                  ref={anchorRef.community}
                  aria-controls={open1.community ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  component={Link}
                  to='/app/dashboard'
                  // onClick={() => handleToggle("community")}
                  // endIcon={<ExpandMoreIcon />}
                  className={menuButtonBtn}
                >
                  ENTER APP
                </Button>
              )}
            </>
            {/* ) : (
              <Button
                ref={anchorRef.community}
                aria-controls={open1.community ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={user.connectWallet}
                className={menuButtonBtn}
              >
                CONNECT
              </Button>
            )} */}
          </div>
        </Drawer>

        <div>
          <Link to='/'>
            <img
              src='/images/logo1.svg'
              alt='Logo'
              className={classes.logoImg1}
            />
          </Link>
        </div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: 'start',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen
              }}
            >
              <BsThreeDotsVertical
                width='60px'
                height='60px'
                style={{ color: '#ffffff', fontSize: '30px' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton1
            }}
            className={classes.btnbox}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      )
    })
  }

  const femmecubatorLogo = (
    <Box>
      <Link to='/'>
        <Logo className='logoImg' />
      </Link>
    </Box>
  )

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton
            }}
          >
            {label}
          </Button>
        </>
      )
    })
  }

  return (
    <>
      <AppBar
        position={history.location.pathname !== '/' ? 'fixed' : 'fixed'}
        elevation={0}
        className='headerbox'
      >
        <Container
          maxWidth={history.location.pathname !== '/' ? 'lg' : 'fixed'}
          className={containerHeight}
        >
          <Hidden smDown>
            {mobileView ? displayMobile() : displayDesktop()}
          </Hidden>
          <Grid container spacing={2} alignItems='center'>
            {layoutCheck ? (
              <Hidden mdUp>
                <Grid item xs={12} sm={12} md={12}>
                  {mobileView ? displayMobile() : displayDesktop()}
                </Grid>{' '}
              </Hidden>
            ) : (
              <Hidden mdUp>
                <Grid item xs={1} sm={1} md={12}>
                  <Toolbar className={classes.toolbar}>
                    <Hidden mdUp>
                      <IconButton
                        color='inherit'
                        onClick={() => toggleSidebar(layoutDispatch)}
                        className={classNames(
                          classes.headerMenuButtonSandwich,
                          classes.headerMenuButtonCollapse
                        )}
                      >
                        {layoutState.isSidebarOpened ? (
                          <MenuIcon
                            classes={{
                              root: classNames(
                                classes.headerIcon,
                                classes.headerIconCollapse
                              )
                            }}
                            // style={{ fontSize: "20px" }}
                            style={{ color: '#ffffff', fontSize: '30px' }}
                          />
                        ) : (
                          <MenuIcon
                            classes={{
                              root: classNames(
                                classes.headerIcon,
                                classes.headerIconCollapse
                              )
                            }}
                            style={{ color: '#ffffff', fontSize: '30px' }}
                          />
                        )}
                      </IconButton>
                    </Hidden>
                    <div className={classes.grow} />
                  </Toolbar>
                </Grid>
                <Grid item xs={11} sm={11} md={12}>
                  {mobileView ? displayMobile() : displayDesktop()}
                </Grid>
              </Hidden>
            )}
          </Grid>
        </Container>
      </AppBar>
    </>
  )
}
